<template>
  <registeration-layout>
    <div class="custom-container">
      <div class="register-container" a-tag="registerLayout">
        <div class="login-header">
          <router-link to="/">
            <img class="logo" src="@/assets/images/logo.svg" alt="" />
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <login-info />
          </div>
          <div class="col-lg-4">
            <div class="register-right-side">
              <div class="register-form">
                <h6 class="form-title">
                  {{ $t("LOGIN_SIGN_UP") }}
                </h6>
                <register
                  :loading="loading"
                  @handleRegister="Register($event)"
                  @handleOpenTermsModal="handleOpenTermsModal"
                />
                <p class="login-title">
                  {{ $t("LOGIN_HAVE_ALREADY_ACCOUNT") }}
                  <router-link to="/login" class="back-link" a-tag="routeLogin">
                    {{ $t("LOGIN_SIGN_IN") }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Terms modal -->
    <terms-modal />
  </registeration-layout>
</template>

<script>
import Register from "@/components/Registeration/Register";
import TermsModal from "@/components/Registeration/TermsModal";
import LoginInfo from "@/components/Registeration/LoginInfo";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Register,
    TermsModal,
    LoginInfo,
  },
  computed: {
    ...mapGetters(["loading"]),
  },

  methods: {
    ...mapActions(["Register"]),

    handleOpenTermsModal() {
      this.$bvModal.show("terms-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
