<template>
  <div>
    <b-modal id="terms-modal" hide-footer size="lg" scrollable>
      <template v-slot:modal-header="{ close }">
        <h6 class="mb-0">
          {{ $t("GLOBAL_TERMS") }}
        </h6>
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div v-if="terms">
        <p v-html="terms.content"></p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getLoadTermsConditionsRequest } from "@/api/register";
export default {
  data() {
    return {
      terms: null,
    };
  },
  created() {
    this.handleLoadTermsAndConditions();
  },
  methods: {
    handleLoadTermsAndConditions() {
      this.ApiService(getLoadTermsConditionsRequest()).then(({ data }) => {
        this.terms = data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
